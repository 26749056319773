import { Injectable, inject } from '@angular/core';
import { UnifiedUser, UnifiedUserInfo } from 'src/app/domain/models/user.model';
import { isLockedSignal, isUserLoggedInSignal } from 'src/app/shared';
import {
  Auth,
  createUserWithEmailAndPassword,
  deleteUser,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword,
  User,
  UserCredential,
} from '@angular/fire/auth';
import { catchError, finalize, from, lastValueFrom, map, Observable, of, retry, switchMap, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { UserApiService } from './user-api.service';
import { Platform } from '@ionic/angular';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { AuthStateService } from '..';
import { SignInWithApple, SignInWithAppleOptions, SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  http = inject(HttpClient);
  service = inject(UserApiService);
  auth = inject(Auth);
  platform = inject(Platform);
  authStateService = inject(AuthStateService);

  signUpWithEmail(email: string, password: string, name: string): Observable<boolean | string> {
    this.authStateService.stopListener();
    return this.service.userExists(email).pipe(
      switchMap((exits) => {
        if (exits) {
          return of(this.handleError('auth/email-already-in-use'));
        } else {
          return from(createUserWithEmailAndPassword(this.auth, email, password)).pipe(
            switchMap((userCredential) => this.handleUserCreation(userCredential, email, password, name)),
            catchError((error) => this.handleSignUpError(error, email)),
          );
        }
      }),
      catchError((error) => {
        console.error('Error in signUpWithEmail:', error);
        return of(this.handleError('server/unknown-error'));
      }),
      finalize(() => from(this.wipeCache())),
    );
  }

  signInWithEmail(email: string, password: string): Observable<UnifiedUserInfo | string> {
    return from(signInWithEmailAndPassword(this.auth, email, password)).pipe(
      switchMap((userCredential) => {
        return this.service.getAll({ where: [{ field: 'uid', operator: '==', value: userCredential.user.uid }] }).pipe(
          switchMap((users) => {
            if (users && users.length > 0) {
              return of(users[0]);
            } else {
              return of(this.handleError('auth/invalid-user'));
            }
          }),
        );
      }),
      catchError((error) => {
        return of(this.handleError(error.code));
      }),
    );
  }

  signInWithGoogle(): Observable<UnifiedUser | null | string> {
    this.authStateService.stopListener();
    if (this.platform.is('capacitor')) {
      return this.capacitorGoogleSignIn();
    } else {
      return this.webGoogleSignIn();
    }
  }

  signInWithApple(): Observable<UnifiedUser | null | string> {
    this.authStateService.stopListener();
    if (this.platform.is('capacitor')) {
      return this.capacitorAppleSignIn();
    } else {
      return this.webAppleSignIn();
    }
  }

  async signOut(): Promise<void> {
    isUserLoggedInSignal.set(false);
    isLockedSignal.set(false);
    await FirebaseAuthentication.signOut();
    await signOut(this.auth);
    await this.wipeCache();
  }

  verifyOTP(email: string, otp: string): Observable<boolean | string> {
    return this.service.verifyOTP(email, otp).pipe(
      catchError(() => {
        return of('Invalid OTP, Please try again.');
      }),
    );
  }

  updatePin(pin: string, isOnSignOn: boolean): Observable<boolean> {
    return this.service.get(this.auth.currentUser?.uid!).pipe(
      switchMap((user) => {
        if (!user) return of(false);

        const updatedUser: UnifiedUser = {
          ...user,
        };

        if (!isOnSignOn) {
          updatedUser.pin = pin;
        } else {
          updatedUser.pin = pin;
          updatedUser.signUpStatus = user.referral !== '' ? 'SetEmergencyPin' : 'Completed';
          updatedUser.activePin = true;
        }

        return this.service.update(user.id!, updatedUser).pipe(
          switchMap((user) => {
            if (user) return of(true);
            else return of(false);
          }),
        );
      }),
    );
  }

  updateSecondPin(secondPin: string, isOnSignOn: boolean): Observable<boolean> {
    return this.service.get(this.auth.currentUser?.uid!).pipe(
      switchMap((user) => {
        if (!user) return of(false);

        const updatedUser: UnifiedUser = {
          ...user,
        };

        if (!isOnSignOn) {
          updatedUser.secondPin = secondPin;
        } else {
          updatedUser.secondPin = secondPin;
          updatedUser.signUpStatus = 'Completed';
          updatedUser.activeEmergencyPin = true;
        }

        return this.service.update(user.id!, updatedUser).pipe(
          switchMap((user) => {
            if (user) return of(true);
            else return of(false);
          }),
        );
      }),
    );
  }

  updatePassword(currentPassword: string, password: string): Observable<boolean | string> {
    const currentUser = this.auth.currentUser;

    if (!currentUser) {
      return of(this.handleError('auth/requires-recent-login'));
    }
    return from(signInWithEmailAndPassword(this.auth, currentUser.email!, currentPassword)).pipe(
      switchMap((signInResult) => {
        if (signInResult && signInResult.user) {
          return from(updatePassword(signInResult.user, password)).pipe(
            switchMap(() => {
              return of(true);
            }),
          );
        } else {
          return of(this.handleError('auth/invalid-credential'));
        }
      }),
      catchError((error) => {
        const errorMessage = this.handleError(error.code);
        return of(errorMessage);
      }),
    );
  }

  getUser(): Observable<UnifiedUser | null> {
    const user = this.auth.currentUser;
    return this.service.get(user?.uid!);
  }

  async getUsersByIds(ids: string[]): Promise<UnifiedUser[]> {
    const users: UnifiedUser[] = await lastValueFrom(
      this.service.getAll({
        where: [
          {
            field: 'uid',
            operator: 'in',
            value: ids,
          },
        ],
      }),
    );

    return users;
  }

  updateUserReferral(email: string, referral: string): Observable<boolean> {
    return this.service.validateOrganization(referral, email.toLowerCase());
  }
  skipUserReferral(email: string): Observable<boolean> {
    return this.service.skippedReferral(email);
  }

  sendOTP(email: string): Observable<boolean> {
    return this.service.sendOTP(email).pipe(
      switchMap((otp) => {
        if (otp) return of(true);
        else return of(false);
      }),
    );
  }

  invalidateOTP(email: string): Observable<boolean> {
    if (email === '') return of(false);
    const user = this.auth.currentUser;

    if (!user) return of(false);

    return this.service.get(user.uid!).pipe(
      switchMap((user) => {
        if (!user) return of(false);

        return this.service
          .update(user.id!, {
            ...user,
            otp: '',
          } as UnifiedUser)
          .pipe(
            switchMap((user) => {
              if (user) return of(true);
              else return of(false);
            }),
          );
      }),
    );
  }

  sendForgotPasswordEmail(email: string): Observable<boolean> {
    return this.service.forgotPassword(email);
  }

  resetPassword(email: string, otp: string, newPassword: string): Observable<boolean> {
    return this.service.getUserByEmail(email).pipe(
      switchMap((user) => {
        if (!user || user.otp !== otp) {
          return of(false);
        }

        return this.service.updatePassword(email, newPassword).pipe(
          switchMap((response) => {
            if (!response.status) {
              return of(false);
            }
            return this.signInAndUpdateUser(email, newPassword, user);
          }),
        );
      }),
    );
  }

  deleteAccount(): Observable<boolean | string> {
    if (!this.auth.currentUser || this.auth.currentUser === null) return of(false);

    const currentUser = this.auth.currentUser;

    return from(currentUser.delete()).pipe(
      switchMap(() => of(true)),
      catchError((error) => {
        if (error.code === 'auth/requires-recent-login') {
          return of(this.handleError('auth/requires-recent-login'));
        } else {
          return of(this.handleError('server/unknown-error'));
        }
      }),
    );
  }

  private signInAndUpdateUser(email: string, newPassword: string, user: UnifiedUser): Observable<boolean> {
    return from(signInWithEmailAndPassword(this.auth, email, newPassword)).pipe(
      switchMap((signInCredential) => {
        return this.service.getAll({
          where: [{ field: 'uid', operator: '==', value: signInCredential.user.uid }],
        });
      }),
      switchMap((users) => {
        if (users && users.length > 0) {
          return this.service
            .update(user.id!, {
              ...user,
              otp: '',
              pin: '',
              secondPin: '',
            } as UnifiedUser)
            .pipe(map((updatedUser) => !!updatedUser));
        }
        return of(false);
      }),
    );
  }

  private handleUserCreation(
    userCredential: UserCredential,
    email: string,
    password: string,
    name: string,
  ): Observable<boolean | string> {
    return from(signInWithEmailAndPassword(this.auth, email, password)).pipe(
      switchMap((signInCredential) => {
        return from(signInCredential.user?.getIdTokenResult()).pipe(
          switchMap((token) => {
            const userInfo: UnifiedUserInfo = {
              ...signInCredential.user,
              displayName: name,
              otp: '',
              pin: '',
              secondPin: '',
              referral: '',
              isActive: true,
              signUpStatus: 'VerifyAccount',
            };
            return this.service.register(userInfo).pipe(
              switchMap((response) => {
                if (response) return of(true);
                else throw new Error('Failed to register user in database');
              }),
              catchError((error) => this.handleRegistrationError(error, userCredential.user.uid)),
            );
          }),
        );
      }),
      retry(2),
    );
  }

  private handleSignUpError(error: any, email: string): Observable<string> {
    console.error('Error during sign up:', error);
    if (error instanceof HttpErrorResponse) {
      // Handle network errors
      return of(this.handleError('server/network-error'));
    } else if (error.code) {
      // Handle Firebase Auth errors
      return of(this.handleError(error.code));
    } else {
      // Handle unknown errors
      return of(this.handleError('server/unknown-error'));
    }
  }

  private handleRegistrationError(error: any, uid: string): Observable<string> {
    console.error('Error during user registration:', error);
    // Attempt to delete the Firebase Auth user if database registration fails
    return from(deleteUser(this.auth.currentUser!)).pipe(
      switchMap(() => of(this.handleError('server/registration-failed'))),
      catchError((deleteError) => {
        console.error('Failed to delete Firebase Auth user:', deleteError);
        return of(this.handleError('server/inconsistent-state'));
      }),
    );
  }

  private capacitorGoogleSignIn(): Observable<UnifiedUser | null | string> {
    return from(FirebaseAuthentication.signOut()).pipe(
      switchMap(() =>
        from(FirebaseAuthentication.signInWithGoogle()).pipe(
          switchMap((result) => {
            console.log('Capacitor Google Sign-In Result:', result);
            if (!result.credential?.idToken) {
              throw new Error('No ID token present in sign-in result');
            }
            const credential = GoogleAuthProvider.credential(result.credential.idToken);
            return from(signInWithCredential(this.auth, credential));
          }),
          switchMap((userCredential) => {
            console.log('Firebase Sign-In Credential:', userCredential);
            return this.handleGoogleSignIn(userCredential.user);
          }),
          catchError((error) => {
            console.error('Error in capacitorGoogleSignIn:', error);
            return this.handleGoogleSignInError(error);
          }),
        ),
      ),
    );
  }

  private capacitorAppleSignIn(): Observable<UnifiedUser | null | string> {
    const options: SignInWithAppleOptions = {
      clientId: 'com.chartpaperappdev.chartpaper', // Match your Apple Developer configuration
      redirectURI: 'https://chart-paper-production.firebaseapp.com/__/auth/handler',
      scopes: 'email',
      state: '12345',
    };

    // Start Apple Sign-In process
    return from(SignInWithApple.authorize(options)).pipe(
      switchMap((result: SignInWithAppleResponse) => {
        console.log('Apple Sign-In Result:', result);

        if (!result.response.identityToken) {
          throw new Error('No identity token received from Apple.');
        }

        // Use OAuthProvider to create Firebase credential
        const provider = new OAuthProvider('apple.com');
        const credential = provider.credential({
          idToken: result.response.identityToken,
        });

        // Sign in with Firebase
        return from(signInWithCredential(this.auth, credential));
      }),
      switchMap((userCredential) => {
        console.log('Firebase Auth User:', userCredential.user);

        // Check or register user in your backend
        return this.handleAppleSignIn(userCredential.user);
      }),
      catchError((error) => {
        console.error('Error during Apple Sign-In:', error);
        return of(this.handleError(error));
      }),
    );
  }

  private webGoogleSignIn(): Observable<UnifiedUser | null | string> {
    const provider = new GoogleAuthProvider();
    return from(signInWithPopup(this.auth, provider)).pipe(
      switchMap((credential) => {
        console.log('Web Google Sign-In Credential:', credential);
        return this.handleGoogleSignIn(credential.user);
      }),
      catchError((error) => {
        console.error('Error in webGoogleSignIn:', error);
        return this.handleGoogleSignInError(error);
      }),
    );
  }

  private webAppleSignIn(): Observable<UnifiedUser | null | string> {
    const auth = getAuth();
    const provider = new OAuthProvider('apple.com');
    return from(signInWithPopup(this.auth, provider)).pipe(
      switchMap((credential) => {
        console.log('Web Apple Sign-In Credential:', credential);
        return this.handleGoogleSignIn(credential.user);
      }),
      catchError((error) => {
        console.error('Error in web Apple SignIn:', error);
        return this.handleAppleSignInError(error);
      }),
    );
  }

  private handleGoogleSignIn(user: User): Observable<UnifiedUser | null | string> {
    console.log('Handling Google Sign-In for user:', user);
    return this.service.getAll({ where: [{ field: 'email', operator: '==', value: user.email! }] }).pipe(
      switchMap((users) => {
        if (users && users.length > 0) {
          console.log('Existing user found:', users[0]);
          return of(users[0]);
        } else {
          console.log('New user, registering...');
          return this.registerGoogleUser(user);
        }
      }),
      catchError((error) => {
        console.error('Error in handleGoogleSignIn:', error);
        return of(this.handleError('server/google-signin-error'));
      }),
    );
  }

  private handleAppleSignIn(user: User): Observable<UnifiedUser | null | string> {
    console.log('Handling Apple Sign-In for user:', user);
    return this.service.getAll({ where: [{ field: 'email', operator: '==', value: user.email! }] }).pipe(
      switchMap((users) => {
        if (users && users.length > 0) {
          console.log('Existing user found:', users[0]);
          return of(users[0]);
        } else {
          console.log('New user, registering...');
          return this.registerAppleUser(user);
        }
      }),
      catchError((error) => {
        console.error('Error in handleGAppleSignIn:', error);
        return of(this.handleError('server/apple-signin-error'));
      }),
    );
  }

  private registerGoogleUser(user: User): Observable<UnifiedUser | null | string> {
    console.log('Registering Google user:', user);
    const userInfo: UnifiedUserInfo = {
      uid: user.uid,
      email: user.email!,
      displayName: user.displayName || '',
      photoURL: user.photoURL || '',
      otp: '',
      pin: '',
      secondPin: '',
      referral: '',
      isActive: true,
      isVerified: true,
      providerId: user.providerId,
    };

    return this.service.register(userInfo).pipe(
      switchMap((response) => {
        if (response) {
          console.log('User registered successfully:', response);
          return of(response);
        }
        throw new Error('Failed to register Google user in database');
      }),
      catchError((error) => {
        console.error('Error in registerGoogleUser:', error);
        return this.handleGoogleRegistrationError(error, user.uid);
      }),
    );
  }

  private registerAppleUser(user: User): Observable<UnifiedUser | null | string> {
    console.log('Registering Apple user:', user);
    const userInfo: UnifiedUserInfo = {
      uid: user.uid,
      email: user.email!,
      displayName: user.displayName || '',
      photoURL: user.photoURL || '',
      otp: '',
      pin: '',
      secondPin: '',
      referral: '',
      isActive: true,
      isVerified: true,
      providerId: user.providerId,
    };

    return this.service.register(userInfo).pipe(
      switchMap((response) => {
        if (response) {
          console.log('User registered successfully:', response);
          return of(response);
        }
        throw new Error('Failed to register Apple user in database');
      }),
      catchError((error) => {
        console.error('Error in registerAppleUser:', error);
        return this.handleAppleRegistrationError(error, user.uid);
      }),
    );
  }

  private handleGoogleSignInError(error: any): Observable<string> {
    console.error('Detailed error in Google sign-in:', error);
    if (error instanceof HttpErrorResponse) {
      return of(this.handleError('server/network-error'));
    } else if (error.code) {
      switch (error.code) {
        case 'auth/popup-closed-by-user':
          return of(this.handleError('auth/popup-closed-by-user'));
        case 'auth/cancelled-popup-request':
          return of(this.handleError('auth/cancelled-popup-request'));
        case 'auth/popup-blocked':
          return of(this.handleError('auth/popup-blocked'));
        default:
          return of(this.handleError(error.code));
      }
    } else {
      return of(this.handleError('server/unknown-error'));
    }
  }

  private handleAppleSignInError(error: any): Observable<string> {
    console.error('Detailed error in Apple sign-in:', error);
    if (error instanceof HttpErrorResponse) {
      return of(this.handleError('server/network-error'));
    } else if (error.code) {
      switch (error.code) {
        case 'auth/popup-closed-by-user':
          return of(this.handleError('auth/popup-closed-by-user'));
        case 'auth/cancelled-popup-request':
          return of(this.handleError('auth/cancelled-popup-request'));
        case 'auth/popup-blocked':
          return of(this.handleError('auth/popup-blocked'));
        default:
          return of(this.handleError(error.code));
      }
    } else {
      return of(this.handleError('server/unknown-error'));
    }
  }

  private handleGoogleRegistrationError(error: any, uid: string): Observable<string> {
    console.error('Error during Google user registration:', error);
    return from(this.auth.currentUser?.delete() ?? Promise.resolve()).pipe(
      switchMap(() => of(this.handleError('server/google-registration-failed'))),
      catchError((deleteError) => {
        console.error('Failed to delete Firebase Auth user after Google registration failure:', deleteError);
        return of(this.handleError('server/inconsistent-state'));
      }),
    );
  }

  private handleAppleRegistrationError(error: any, uid: string): Observable<string> {
    console.error('Error during Apple user registration:', error);
    return from(this.auth.currentUser?.delete() ?? Promise.resolve()).pipe(
      switchMap(() => of(this.handleError('server/apple-registration-failed'))),
      catchError((deleteError) => {
        console.error('Failed to delete Firebase Auth user after Apple registration failure:', deleteError);
        return of(this.handleError('server/inconsistent-state'));
      }),
    );
  }

  private handleError(errorCode: string): string {
    // Map Firebase error codes to user-friendly error messages
    const errors: { [key: string]: string } = {
      'auth/email-already-in-use': 'This email is already in use by another account.',
      'auth/weak-password': 'The password is too weak. Please choose a stronger password.',
      'auth/invalid-email': 'The email address is not valid. Please enter a valid email address.',
      'auth/invalid-credential': 'Your credentials is not valid.',
      'auth/too-many-requests': 'Too many requests. Please try again later.',
      'auth/user-not-found': 'User not found. Please check your email and try again.',
      'auth/wrong-password': 'Incorrect password. Please try again.',
      'auth/user-disabled': 'Your account has been disabled. Please contact support.',
      'auth/requires-recent-login': 'Your session has expired. Please log in again.',
      'auth/invalid-user': 'Invalid user. Please try again.',
      'auth/argument-error': 'Invalid arguments. Please try again.',
      'server/network-error': 'Network error occurred. Please check your internet connection and try again.',
      'server/registration-failed': 'Failed to complete registration. Please try again or contact support.',
      'server/unknown-error': 'An unexpected error occurred. Please try again later.',
      'server/inconsistent-state': 'An error occurred during registration. Please contact support for assistance.',
      'auth/popup-closed-by-user': 'The Google sign-in popup was closed. Please try again.',
      'auth/cancelled-popup-request': 'The sign-in process was cancelled. Please try again.',
      'auth/popup-blocked':
        'The sign-in popup was blocked by your browser. Please allow popups for this site and try again.',
      'server/google-signin-error': 'An error occurred during Google sign-in. Please try again later.',
      'server/google-registration-failed':
        'Failed to complete Google registration. Please try again or contact support.',
      // Add more Firebase error codes and messages as needed
    };

    // Return a user-friendly error message or a generic message if the error code is not mapped
    return errors[errorCode] || 'An unexpected error occurred. Please try again later.';
  }

  private async wipeCache() {
    await this.service.wipeData();
  }
}
